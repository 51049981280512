<!--
 * @Author: your name
 * @Date: 2020-10-26 16:19:04
 * @LastEditTime: 2020-12-21 10:18:41
 * @LastEditors: Please set LastEditors
 * @Description: 停水复水通知
 * @FilePath: \nethallofficialaccounts\src\views\Setting\notice.vue
-->
<template>
    <div class="noticePage" v-if="dataInfoFlag">
        <p>请选择消息提醒方式</p>
        <div class="content" v-for="(item,index) in dataInfo" :key="index">
            <span>{{item.switchName}}</span>
            <van-switch v-model="item.switchStatus" active-color="#4CD964" @change="getSwitchValue(index)"/>
        </div>
    </div>
    <no-data v-else />
</template>

<script>
import NoData from '@/components/noData';
import { showStopOrResumeNotice, updateNotice, selectDictEnumByCode } from "../../api/home";

export default {
    name: 'Notice',
    data() {
        return {
            dataInfo: [],
            dataInfoFlag: true,
            noticeTypeData: []
        }
    },
    components: {
        NoData
    },
    mounted() {
        this.selectDictEnumByCode();
    },
    methods: {
        async selectDictEnumByCode() {
            const {status, resultData} = await selectDictEnumByCode('NOTICE_TYPE');
            if (status === 'complete') {
               this.noticeTypeData = resultData;
               this.showStopOrResumeNotice();
            }
        },
        async showStopOrResumeNotice() {
            const {status, resultData} = await showStopOrResumeNotice();
            if (status === 'complete') {
                if (resultData && resultData.length > 0) {
                    this.noticeTypeData.map((item) => {
                        resultData.map((list) => {
                            if (item.code === list.switchCode) {
                                list.switchName = item.name
                            }
                        })
                    })
                    this.dataInfo = resultData
                    this.dataInfoFlag = true
                } else {
                    this.dataInfoFlag = false
                }
            }
        },
        async getSwitchValue(index) {
            let {switchStatus, cbusNotifySwitchId} = this.dataInfo[index]
            let arg = {
                switchStatus,
                cbusNotifySwitchId
            }
            const {status, resultData} = await updateNotice(arg);
            if (status === 'complete') {
                this.showStopOrResumeNotice()
            }
        }
    }
}
</script>

<style lang="less" scoped>
.noticePage {
    p {
        margin: 0px;
        font-size: 14px;
        padding-left: 16px;
        line-height: 22px;
        padding-top: 20px;
        padding-bottom: 5px;
        color: rgba(0,0,0,0.45);
    }
    .content {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid #eeeeee;
        background: #ffffff;
        span {
            font-size: 14px;
        }
        .van-switch {
            margin-top: 9px;
        }
    }
    .content:last-child {
        border-bottom: 0px solid transparent;
    }
}
</style>